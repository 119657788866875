//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './TableFilters.vue';
import constant from '~/const';

export default {
  components: { Filters },

  data() {
    return {
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapState('currencies', ['currencies']),
    ...mapGetters('currencies', ['toCurrencyFormat']),

    ...mapState('statisticsReferralProgram', ['page', 'pages', 'data', 'sort']),

    statisticsCurrencyList() {
      const { currencies } = this;
      return currencies.map((e) => ({
        currencyId: e.id,
        currencyTitle: e.title,
      }));
    },

    tableConfig() {
      const { statisticsCurrencyList } = this;
      return statisticsCurrencyList.map((e) => ({
        width: 100 / statisticsCurrencyList.length,
        header: {
          type: 'text',
          caption: e.currencyTitle,
        },
        cell: {
          type: 'slot',
          name: e.currencyTitle,
        },
      }));
    },

    tableTypesConfig() {
      return [
        {
          width: 100,
          header: {
            type: 'text',
            caption: '',
          },
          cell: {
            type: 'text',
            value: (e) => this.$t(e.caption),
          },
        },
      ];
    },

    tableTotalConfig() {
      return [
        {
          width: 100,
          header: {
            type: 'text',
            caption: 'Total',
          },
          cell: {
            type: 'slot',
            name: 'cumulativeAmount',
          },
        },
      ];
    },

    tableWidth() {
      const { statisticsCurrencyList } = this;
      return statisticsCurrencyList.length * 200;
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('statisticsReferralProgram', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getCurrencyStatResult(type, currencyTitle) {
      const { data } = this;
      const amount = data[type]?.incomeStats?.find(
        (e) => e.currencyTitle === currencyTitle,
      )?.amount;
      if (!amount) return 0;
      return this.toCurrencyFormat(amount, currencyTitle);
    },
  },
};
